import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { LocalizedMatch } from "../../../types/match"
import { Collapse, Spin } from "antd"
import { MatchList } from "@components/Match/MatchList"
import { LocalizedCountry } from "../../../types/country"
import Image from "next/image"
import Link from "next/link"
import { getCompetitionSeasonGroupPageUrl } from "../../../utils/urls"

const { Panel } = Collapse

type MatchAccordionData = {
  groupId: number
  label: string
  country?: LocalizedCountry | null
  competitionCalcioComSlug: string | undefined | null
  seasonCalciocomSlug: string | undefined | null
  groupCalciocomSlug: string | undefined | null
}

function MatchAccordionHeader({ data }: { data: MatchAccordionData }) {
  const country = data.country
  return (
    <div>
      {country && country.image && (
        <>
          <span style={{ marginRight: "5px", alignItems: "center", display: "flex" }}>
            <Image
              src={country.image.path}
              alt={country.name}
              className={"countryImage"}
              layout={"intrinsic"}
              width={20}
              height={20}
            />
          </span>
          <span style={{ marginRight: "3px" }}>{`${country.name.toUpperCase()}:`}</span>
        </>
      )}
      {data.competitionCalcioComSlug && data.seasonCalciocomSlug && data.groupCalciocomSlug ? (
        <span className={"go-to-competition"}>
          <Link
            href={getCompetitionSeasonGroupPageUrl(
              data.competitionCalcioComSlug,
              data.seasonCalciocomSlug,
              data.groupCalciocomSlug,
            )}
          >
            <a title={data.label}>{data.label}</a>
          </Link>
        </span>
      ) : (
        <span>{data.label}</span>
      )}
    </div>
  )
}

export function MatchAccordions({
  matches,
  date,
  loading = false,
  maxGroup,
}: {
  matches: LocalizedMatch[]
  date?: string
  loading?: boolean
  maxGroup?: number
}) {
  const [activeKey, setActiveKey] = useState<string | string[] | undefined>(undefined)

  const [groups, setGroups] = useState<MatchAccordionData[]>([])

  const [loadActiveKey, setLoadActiveKey] = useState<boolean>(true)

  useEffect(() => {
    const newGroups: MatchAccordionData[] = []
    const newActiveKey = new Set<string>()

    matches.forEach((match) => {
      const groupId = match.groupId

      if (!newGroups.find((group) => group.groupId === groupId)) {
        const phaseName = match.phase?.name
        const groupName = match.group?.name

        const names = []

        if (match.competition) {
          names.push(match.competition.name)
        }

        if (phaseName !== undefined && groupName !== undefined && phaseName !== groupName) {
          names.push(phaseName)
          names.push(groupName)
        }

        newGroups.push({
          groupId: groupId,
          label: `${names.join(" - ")}`,
          country: match.competition?.country,
          competitionCalcioComSlug: match.competition?.calciocomSlug,
          seasonCalciocomSlug: match.season?.calciocomSlug,
          groupCalciocomSlug: match.group?.calciocomSlug,
        })
      }
    })

    setGroups(newGroups.slice(0, maxGroup))

    if (loadActiveKey || activeKey === undefined) {
      newGroups.forEach((group) => {
        newActiveKey.add(group.groupId.toString())
      })

      setActiveKey([...newActiveKey])

      setLoadActiveKey(false)
    }
  }, [matches])

  useEffect(() => {
    setLoadActiveKey(true)
  }, [date])

  return (
    <Col className={"match-accordions"}>
      <Spin spinning={loading}>
        <Collapse
          activeKey={activeKey}
          onChange={(value) => {
            setActiveKey(value)
          }}
          accordion
        >
          {groups.length > 0 ? (
            groups.map((group) => {
              return (
                <Panel
                  header={<MatchAccordionHeader data={group} />}
                  key={group.groupId.toString()}
                  extra={
                    !activeKey?.includes(group.groupId.toString()) && (
                      <span className={"match-accordions-show-matches-message"}>{`mostra partite (${
                        matches.filter((m) => m.groupId === group.groupId).length
                      })`}</span>
                    )
                  }
                >
                  <MatchList matches={matches.filter((m) => m.groupId === group.groupId)} />
                </Panel>
              )
            })
          ) : (
            <MatchList matches={matches} />
          )}
        </Collapse>
      </Spin>
    </Col>
  )
}
