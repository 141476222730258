import { LocalizableCompetition } from "types/competition"
import { LocalizableEntity } from "types/languages"
import { LocalizableSeason } from "types/season"
import { LocalizableCompetitionSeason } from "types/competitionSeason"
import { LocalizableTeam } from "types/team"
import { LocalizablePhase } from "types/phase"
import { LocalizableGroup } from "types/group"
import { LocalizableTurn } from "types/turn"
import { LocalizableStadium } from "types/stadium"
import { Match as MatchDb } from "../db/models/Match"
import { News as NewsDb } from "../db/models/News"
import { LocalizedNews, SimplifiedNews } from "./news"

export type MatchResult = {
  homeScore: number | null
  awayScore: number | null
  homeScore1T: number | null
  awayScore1T: number | null
  homeScore2T: number | null
  awayScore2T: number | null
  homeScore3T: number | null
  awayScore3T: number | null
  homeScore1HT: number | null
  awayScore1HT: number | null
  homeScore2HT: number | null
  awayScore2HT: number | null
  homeScoreP: number | null
  awayScoreP: number | null
}

export type MatchDuration = {
  duration: number | null
  duration1T: number | null
  duration2T: number | null
  duration3T: number | null
  duration1HT: number | null
  duration2HT: number | null
  durationP: number | null
}

export type LocalizableMatch<localized extends boolean> = LocalizableEntity<
  localized,
  {
    id: number
    competitionId: number
    seasonId: number
    competitionSeasonId: number
    phaseId: number
    groupId: number
    turnId: number
    homeTeamId: number
    awayTeamId: number
    stadiumId: number | null
    date: string | null
    endedAt: string | null
    matchDay: number | null
    calciocomSlug: string | null
    // stadium: Stadium,
    // players: MatchTeamPlayer,
    // referee: RefereePerson //??
    // providers
    kamaId?: number | null
    fromKama?: boolean | null
    heimspielId: number | null
    hasPostMatchStats?: number | null

    result?: string | null
    resultWithPartialScores?: string | null

    status?: MatchStatus | null
    ended?: boolean | null
    currentMinute?: number | null

    competition?: LocalizableCompetition<localized> | null
    season?: LocalizableSeason<localized> | null
    competitionSeason?: LocalizableCompetitionSeason<localized> | null
    phase?: LocalizablePhase<localized> | null
    group?: LocalizableGroup<localized> | null
    turn?: LocalizableTurn<localized> | null
    homeTeam?: LocalizableTeam<localized> | null
    awayTeam?: LocalizableTeam<localized> | null
    stadium?: LocalizableStadium<localized> | null
  }
> &
  MatchResult &
  MatchDuration &
  (localized extends false
    ? {
        // these are read only, automatically set with a trigger in the db
        readonly name_en?: string
        readonly name_it?: string | null
      }
    : {
        readonly name?: string
      })

export type Match = LocalizableMatch<false>
export type LocalizedMatch = LocalizableMatch<true>

export const MatchLiveStatusValues = [
  "notStarted",
  "inPlay1H",
  "inPlay2H",
  "inPlay1EH",
  "inPlay2EH",
  "inPlayP",
  "halfTime",
  "breakTime",
]

export const MatchStatusValues = [
  ...MatchLiveStatusValues,
  "ended",
  "endedAfterExtraTime",
  "endedAfterPenalties",
  "postponed",
  "cancelled",
  "abandoned",
  "interrupted",
  "suspended",
  "delayed",
]
export type MatchStatus = typeof MatchStatusValues[number]

export type MatchWithNews = {
  match: MatchDb
  news: NewsDb
}

export type LocalizedMatchWithNews = {
  match: LocalizedMatch
  news: LocalizedNews
}
export type LocalizedMatchWithSimplifiedNews = {
  // match: LocalizedMatch
  match: SimplifiedMatches
  news: SimplifiedNews
}

export type SimplifiedMatches = Pick<
  LocalizedMatch,
  | "id"
  | "competitionSeasonId"
  | "awayTeamId"
  | "homeTeamId"
  | "calciocomSlug"
  | "competition"
  | "competitionId"
  | "awayTeam"
  | "homeTeam"
  | "awayScore"
  | "date"
  | "duration"
  | "homeScore"
  | "result"
>
