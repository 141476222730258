import React from "react"
import { Row, Col } from "react-bootstrap"
import PredictionImageBookmaker from "./PredictionImageBookmaker"
import { SimplifiedNews } from "types/news"

type Props = {
  news: SimplifiedNews
  col: number
  color?: "yellow"
  roundBorders?: boolean
}

export default function PredictionQuotes({ roundBorders = true, ...props }: Props) {
  const { news, col, color } = props
  return news?.bookmakerQuotes?.length && news?.bookmakerQuotes?.length > 0 ? (
    <Col lg={col} md={12} className={`bookmakers-container-prediction-quotes ${color ? color : ""}`}>
      <>
        <Row
          className="bookmaker-container-title-value"
          style={roundBorders ? { borderTopLeftRadius: "10px", borderTopRightRadius: "10px" } : {}}
        >
          <span>{news?.quoteTitle}</span>
        </Row>
        <div
          className={"bookmakers-container"}
          style={roundBorders ? { borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" } : {}}
        >
          {news?.bookmakerQuotes
            ?.slice(0, 3)
            .reverse()
            .map((quote, i) => quote.bookmakerBonus?.image?.path && <PredictionImageBookmaker key={i} quote={quote} />)}
        </div>
      </>
    </Col>
  ) : (
    <></>
  )
}
