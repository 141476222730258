import Link from "next/link"
import classNames from "classnames"
import { getTeamPageUrl } from "../../../utils/urls"
import { LocalizedGroupTeamStandingWithParameterValue } from "../../../types/groupTeamStanding"
import * as ImageConstants from "../../../constants/images"
import Image from "next/image"
import React from "react"
import { Tooltip, OverlayTrigger } from "react-bootstrap"

function valueToTableCellValue(value: number | null) {
  return value === null ? "-" : value
}

export function GroupStandingOld({
  competitionCalciocomSlug,
  seasonCalciocomSlug,
  teamStandings,
  homeTeamId = null,
  awayTeamId = null,
  unitMeasurement = null,
  enableTeamLink = true,
  hiddenParameters = [],
}: {
  competitionCalciocomSlug: string | null
  seasonCalciocomSlug: string | null
  teamStandings: LocalizedGroupTeamStandingWithParameterValue[]
  homeTeamId?: number | null
  awayTeamId?: number | null
  unitMeasurement?: string | null
  enableTeamLink?: boolean
  hiddenParameters?: string[]
}) {
  const tooltips = [
    { id: "PG", text: "PG", content: "Partite Giocate" },
    { id: "V", text: "V", content: "Vittorie" },
    { id: "P", text: "P", content: "Pareggi" },
    { id: "S", text: "S", content: "Sconfitte" },
    { id: "GF:GS", text: "GF:GS", content: "Gol Fatti : Gol Subiti" },
    { id: "DIF", text: "DIF", content: "Differenza Reti" },
    { id: "PT", text: "Pt", content: "Punti" },
  ].filter((tooltip) => !hiddenParameters.includes(tooltip.id))
  return teamStandings.length > 0 ? (
    <div className="bet-standing-widget-cc hs-block hs-standing new-groupStanding" id={"standing"}>
      <div className="container-div">
        <table>
          <thead>
            <tr>
              <th className="standing-rank standing-rank-">#</th>
              <th className="team-autoimage team-autoimage-" />
              <th className="team-name team-name- text-left">Squadra</th>
              {teamStandings?.[0]?.parameterValue !== undefined && (
                <th className="standing-points standing-parameter-value- frequency-label">{"Frequenza"}</th>
              )}
              {(() => {
                const headerCells = []
                for (let i = 0; i < tooltips.length; i++) {
                  const tooltip = tooltips[i]
                  headerCells.push(
                    <OverlayTrigger
                      key={tooltip.id}
                      placement={"top"}
                      overlay={
                        <Tooltip id={tooltip.id}>
                          <div className="new-tooltip-container">{tooltip.content}</div>
                        </Tooltip>
                      }
                    >
                      <th>{tooltip.text}</th>
                    </OverlayTrigger>,
                  )
                }
                return headerCells
              })()}
            </tr>
          </thead>
          <tbody>
            {teamStandings.map((teamStanding, index) => {
              const team = teamStanding.team!
              const isOdd = (index + 1) % 2 === 1

              //TODO: tr: add className "color-1 ... color-5" for champions ecc..

              const teamUrl = getTeamPageUrl(team.calciocomSlug!, competitionCalciocomSlug, seasonCalciocomSlug)

              return (
                <tr
                  key={index}
                  className={classNames(
                    "standing standing-" + (index + 1),
                    index === 0 && "first",
                    isOdd ? "odd" : "even",
                    homeTeamId && homeTeamId === team.id && "current-home-team",
                    awayTeamId && awayTeamId === team.id && "current-away-team",
                  )}
                >
                  <td className="rank standing-rank">{index + 1}</td>

                  <td className="team-image team-image-">
                    <div className={"team-image-div"}>
                      {enableTeamLink ? (
                        <Link href={teamUrl}>
                          <a title={team.name}>
                            <Image
                              blurDataURL={ImageConstants.TEAM_MISSING_IMAGE_PATH}
                              placeholder="blur"
                              src={team.image?.path || ImageConstants.TEAM_MISSING_IMAGE_PATH}
                              alt={team.name}
                              title={team.name}
                              layout={"intrinsic"}
                              width={24}
                              height={24}
                            />
                          </a>
                        </Link>
                      ) : (
                        <Image
                          blurDataURL={ImageConstants.TEAM_MISSING_IMAGE_PATH}
                          placeholder="blur"
                          src={team.image?.path || ImageConstants.TEAM_MISSING_IMAGE_PATH}
                          alt={team.name}
                          title={team.name}
                          layout={"intrinsic"}
                          width={24}
                          height={24}
                        />
                      )}
                    </div>
                  </td>
                  <td className="team-name team-name-">
                    {enableTeamLink ? (
                      <Link href={teamUrl}>
                        <a title={team.name}>{team.name}</a>
                      </Link>
                    ) : (
                      <>{team.name}</>
                    )}
                  </td>
                  {teamStanding.parameterValue !== undefined && (
                    <td className="standing-parameter-value">
                      {`${teamStanding.parameterValue}${unitMeasurement ? unitMeasurement : ""}`}
                    </td>
                  )}
                  {!hiddenParameters.includes("PG") && (
                    <td className="standing-games_played">{valueToTableCellValue(teamStanding.matches)}</td>
                  )}
                  {!hiddenParameters.includes("V") && (
                    <td className="standing-win">{valueToTableCellValue(teamStanding.win)}</td>
                  )}
                  {!hiddenParameters.includes("P") && (
                    <td className="standing-draw">{valueToTableCellValue(teamStanding.draw)}</td>
                  )}
                  {!hiddenParameters.includes("S") && (
                    <td className="standing-lost">{valueToTableCellValue(teamStanding.lost)}</td>
                  )}
                  {!hiddenParameters.includes("GF:GS") && (
                    <td className="standing-goaldiff">{`${valueToTableCellValue(
                      teamStanding.score,
                    )}:${valueToTableCellValue(teamStanding.scoreAgainst)}`}</td>
                  )}
                  {!hiddenParameters.includes("DIF") && (
                    <td className="standing-difference">{valueToTableCellValue(teamStanding.difference)}</td>
                  )}
                  {!hiddenParameters.includes("PT") && (
                    <td className="standing-points">{valueToTableCellValue(teamStanding.points)}</td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <div className="live-text-default">Non ci sono dati disponibili</div>
  )
}
