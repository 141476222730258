import { LocalizedMatchWithSimplifiedNews } from "types/match"
import { Col, Row } from "react-bootstrap"
import { getNewsPageUrl, getTeamPageUrl } from "../../../../utils/urls"
import React from "react"
import moment from "moment"
import Image from "next/image"
import Link from "next/link"
import PredictionQuotes from "../PredictionQuotes"
import style from "./style.module.scss"
import * as ImageConstants from "../../../../constants/images"

export const PredictionMatchCol = ({ matchWithNews }: { matchWithNews: LocalizedMatchWithSimplifiedNews }) => {
  const match = matchWithNews.match
  const news = matchWithNews.news
  const isQuotes = !!matchWithNews?.news?.bookmakerQuotes?.length

  const homeTeamUrl = getTeamPageUrl(match.homeTeam!.calciocomSlug!)
  const awayTeamUrl = getTeamPageUrl(match.awayTeam!.calciocomSlug!)

  return (
    <Row className={style.predictionMatchColContainer}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className={style.bannerContainer}>
        <div className={style.competitionBanner}>
          <span>{match.competition?.name}</span>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className={style.mainRow}>
        <Row className={style.predicitonInfo}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className={style.date}>
            <span>{moment(match.date).format("D MMM YYYY")}</span>
          </Col>
          <Col xs={5} sm={5} md={5} lg={5} xl={5} className={style.teamInfo}>
            <Row>
              {homeTeamUrl ? (
                <>
                  <Link href={homeTeamUrl} passHref>
                    <a className={style.teamImg}>
                      {match.homeTeam?.image?.path && (
                        <Image
                          blurDataURL={ImageConstants.TEAM_MISSING_IMAGE_PATH}
                          placeholder="blur"
                          src={match.homeTeam.image.path}
                          alt={match.homeTeam.name}
                          width={40}
                          height={40}
                        />
                      )}
                    </a>
                  </Link>
                  <Link href={homeTeamUrl} passHref>
                    <a>
                      <div className={style.teamName}>{match.homeTeam?.name}</div>
                    </a>
                  </Link>
                </>
              ) : (
                <>
                  {match.homeTeam?.image?.path && (
                    <Image
                      blurDataURL={ImageConstants.TEAM_MISSING_IMAGE_PATH}
                      placeholder="blur"
                      src={match.homeTeam.image.path}
                      alt={match.homeTeam.name}
                      width={40}
                      height={40}
                    />
                  )}
                  <div className={style.teamName}>{match.homeTeam?.name}</div>
                </>
              )}
            </Row>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className={style.vsContainer}>
            <div className={style.vs}>VS</div>
          </Col>
          <Col xs={5} sm={5} md={5} lg={5} xl={5} className={style.teamInfo}>
            {awayTeamUrl ? (
              <>
                <Link href={awayTeamUrl} passHref>
                  <a className={style.teamImg}>
                    {match.awayTeam?.image?.path && (
                      <Image
                        blurDataURL={ImageConstants.TEAM_MISSING_IMAGE_PATH}
                        placeholder="blur"
                        src={match.awayTeam.image.path}
                        alt={match.awayTeam.name}
                        width={40}
                        height={40}
                      />
                    )}
                  </a>
                </Link>
                <Link href={awayTeamUrl} passHref>
                  <a>
                    <div className={style.teamName}>{match.awayTeam?.name}</div>
                  </a>
                </Link>
              </>
            ) : (
              <>
                {match.awayTeam?.image?.path && (
                  <Image
                    blurDataURL={ImageConstants.TEAM_MISSING_IMAGE_PATH}
                    placeholder="blur"
                    src={match.awayTeam.image.path}
                    alt={match.awayTeam.name}
                    width={40}
                    height={40}
                  />
                )}
                <div className={style.teamName}>{match.awayTeam?.name}</div>
              </>
            )}
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className={style.predictionQuotesContainer}>
        {isQuotes && <PredictionQuotes col={12} news={matchWithNews.news} />}
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className={style.buttonContainer}>
        <Link href={getNewsPageUrl(news!)} passHref legacyBehavior>
          <a>VAI AL PRONOSTICO</a>
        </Link>
      </Col>
    </Row>

    //     <Modal
    //       visible={isModalOpen}
    //       className={"e2-betting-modal-cc"}
    //       closable={true}
    //       onCancel={handleCancel}
    //       footer={null}
    //       afterClose={() => {
    //         if (typeof window !== "undefined") {
    //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //           // @ts-ignore
    //           window.document.title = pageTitle
    //         }
    //       }}
    //     >
    //       <Row>
    //         <Col xs={12} className={"order-1"}>
    //           <Row>
    //             <Col xs={12} lg={4} className={"date-col order-lg-first order-last"}>
    //               {moment(matchWithNews.match.date).format("DD/MM/YYYY")}
    //             </Col>
    //             <Col xs={12} lg={4} className={"title-col order-lg-last order-first"}>
    //               Compara quote
    //             </Col>
    //           </Row>
    //         </Col>
    //         <Col xs={12} className={"order-lg-2 order-3"}>
    //           <Row>
    //             <PredictionMatchRow matchWithNews={matchWithNews} showModalButton={false} />
    //           </Row>
    //         </Col>
    //         <Col xs={12} className={"order-lg-3 order-2"}>
    //           <E2Iframe csi={true} index={1} />
    //         </Col>
    //       </Row>
    //     </Modal>
  )
}
