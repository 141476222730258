import { LocalizedMatchWithSimplifiedNews } from "types/match"
import { Col, Row } from "react-bootstrap"
import style from "./style.module.scss"
import { PredictionMatchCol } from "../PredictionMatchCol/PredictionMatchCol"
import Link from "next/link"

export const VerticalPredictionMatchList = ({
  matchWithNewsList,
}: {
  matchWithNewsList: LocalizedMatchWithSimplifiedNews[]
}) => {
  return (
    <Row className={style.predictionsContainer}>
      {matchWithNewsList.map((matchWithNews, i) => {
        return (
          <Col xs={12} sm={12} md={6} lg={6} xl={6} key={i} style={{ backgroundColor: "#eaeaea" }}>
            <PredictionMatchCol matchWithNews={matchWithNews} />
          </Col>
        )
      })}
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className={style.predictionFooter}>
        <Link href="/scommesse/quote-pronostici" passHref>
          <a>VEDI TUTTI I PRONOSTICI</a>
        </Link>
      </Col>
    </Row>
  )
}
